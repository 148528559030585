<template>
  
<div class="px-5 py-5">
                
                <div class="flex py-3 justify-start align-middle px-5 mt-5" v-if="!isNew && !isEdit">
                    <da-button-primary  @click.native="isNew = true" :label="'AGREGAR PERSONAL'" rxl swpr class="py-1 px-3 font-light"></da-button-primary>
                </div>

                <div v-if="isNew" class="py-5 flex justify-center sm:mr-5 sm:ml-5 md:mr-10 md:ml-10" :class="{'animate-fade-in-down': isNew}" >
                    <da-add-user class="sm:w-full md:w-full lg:w-full" v-on:success="onSuccess" v-on:close="isNew = false"></da-add-user>
                </div>

                <div v-if="isEdit" class="py-5 flex justify-center sm:mr-5 sm:ml-5 md:mr-10 md:ml-10" :class="{'animate-fade-in-down': isEdit}" >
                    <da-edit-user  class="sm:w-full md:w-full lg:w-full" :id="currentID"  v-on:success="onSuccessEdit" v-on:close="isEdit = false"></da-edit-user>
                </div>
                
                <div class="py-5 mr-5 ml-5 mb-5">
                    <da-datatable :headers="headers" :values="users" :elevation="3">
                        <template #phone="{data, header}">
                            <da-scoped-column>
                                <template #header>{{header ? header : '' }}</template>
                                <template #value>{{ data.phone | phone }} </template>
                            </da-scoped-column>
                        </template>
                        <template #action="{ data }">
                            <div class="flex sm:px-0 md:px-8 lg:px-8">
                                <da-button-actions @click.native="onEvent(ACTIONS.EDIT, data.id_login)" swlg class="bg-primary-500 hover:bg-primary-700 text-primary-900  flex justify-center align-middle   rounded-tl-md rounded-bl-md">
                                    <template>
                                        <div class="flex sm:w-auto md:w-auto lg:w-9 xl:w-9 justify-center items-center gap-1">
                                            <f-icon class="w-3 h-3" :icon="['fas', 'pen']"></f-icon>
                                            <small style="font-size: 8px; line-height: 10px; font-style: normal;" class="font-sans font-medium">EDITAR</small>
                                        </div>
                                    </template>
                                </da-button-actions>
                                <da-button-actions @click.native="onEvent(ACTIONS.DELETE,data.id_login)" swlg class="bg-primary-900 text-primary-500 hover:bg-primary-1000  flex justify-center align-middle   rounded-tr-md rounded-br-md ">
                                    <template>
                                        <div class="flex sm:w-auto md:w-auto lg:w-9 xl:w-9 justify-center items-center gap-1">
                                            <f-icon class="w-3 h-3" :icon="['fas', 'trash']"></f-icon>
                                            <small style="font-size: 8px; line-height: 10px; font-style: normal;" class="font-medium">ELIMINAR</small>
                                        </div>
                                    </template>
                                </da-button-actions>
                            </div>
                        </template>
                    </da-datatable>
                </div>


    <da-dialog v-model="isDelete" :persist="true">
        <template #body>
            <da-dialog-confirm 
            :title="'¿Eliminar Personal?'" 
            :confirmcolor="'#F83D3D'"
            :message="'¿Desea eliminar el usuario seleccionado?, esta acción no se puede revertir.'" 
            v-model="currentID" 
            v-on:confirm="onDelete" 
            v-on:close="isDelete = false">
        </da-dialog-confirm>
        </template>
    </da-dialog>

</div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import VuexMixin from '../../mixins/VuexMixin';
import moduleTypes from '../../store/modules/common/module-types';
import fnTypes from '../../store/modules/common/fn-types';
import DAAddUser from './DAAddUser.vue';
import DAEditUser from './DAEditUser.vue';
import ACTIONS from '../../utils/common/actions';
import DAUserModel from '../../models/user/DAUserModel';

export default {
    mixins: [VuexMixin],
    data: function() {
        return {
            headers: [
                { text: 'Nombre', value: 'name' },
                { text: 'Teléfono', value: 'phone' },
                { text: 'Correo', value: 'email' },
                { text: 'Puesto', value: 'desc_rol' },
                { text: 'Acción', value: 'action' }
            ],
            tab:1,
            isNew: true,
            isDelete: false,
            isEdit: false,
            ACTIONS,
            currentID: '',
            consentimiento: '',
            declaracion: '',
            nombredr: '',
            id_signature_consent: '',
            key_signature: '',
            newSignature: '',
            url_signature:'',
            idsignatunature:'',
            updateSignature: false,
            isUpdateModel: false,
            isModify: true,
            userModel: new DAUserModel()
        }
    },
    components: {
        'da-add-user': DAAddUser,
        'da-edit-user': DAEditUser,
    },
    created() {
        this.init()
    },
    computed: {
     ...mapGetters(moduleTypes.USER, {
            users: fnTypes.getUsers,
            hasErrorInService: fnTypes.hasErrorInService
       }),
    },
    methods: {
        ...mapActions(moduleTypes.USER, [
            fnTypes.update
        ]),

        init: async function() {
            this.isNew = false;
            this.isEdit = false;
            await this.ActionWrapp(moduleTypes.USER, fnTypes.getUsers)
        },

        onEvent: function(type, id) {
            this.currentID = id;
            switch(type) {
                case this.ACTIONS.DELETE:
                this.isDelete = true;
                break
                case this.ACTIONS.EDIT:
                this.isEdit = true;
                break
            }
        },

        onDelete: async function(id) {
            this.isDelete = false;
            let item = this.users.find(x => x.id_login == id)
            if(item) {
                item.active = false
                await this.update({data: item, id: id})
            }
        },

        onSuccess: function() {
            this.init()
        },

        onSuccessEdit: function() {
            this.isEdit = false;
        },


    }

}
</script>

<style>

</style>